


















































import MBlockItem from "@/components/mobile/m-blockItem.vue";
import mChainSearch from "@/components/mobile/m-chainSearch.vue";
import MPageContainer from "@/components/mobile/m-pageContainer.vue";
import MTitle from "@/components/mobile/m-title.vue";
import VueTypedMixins from "vue-typed-mixins";
import blockMixin from "@/mixin/block";
export default VueTypedMixins(blockMixin).extend({
  components: { mChainSearch, MPageContainer, MTitle, MBlockItem },
});
